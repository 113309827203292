<template>
  <div class="row align-items-center">
    <div class="col-sm-9">
      <h3 class="text-left">Engine Aux Maker Settings</h3>
    </div>
    <div class="col-sm-3 text-right">
      <button class="e-btn e-btn-green e-text-white pr-3 mt-2" @click="resetForm" data-toggle="modal" data-target="#engineAuxMakerModal">
        <font-awesome-icon icon="plus-square" style="width: 25px"/>
        <small class="font-weight-bold">
          NEW ENGINE AUX MAKER
        </small>
      </button>
    </div>
    <div class="col-12 pl-0 text-center col-lg-12">
      <div class="row no-gutters">
        <nav aria-label="..." class="col-8">
          <ul class="pagination text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
        <div class="col-4">
          <input type="text" name="searchUser"  class="form-control form-control-sm" id="" placeholder="Search Engine Aux Maker" v-model="searchParams.search">
        </div>
      </div>
    </div>
    <table class="table table-sm table-bordered small mt-2">
      <thead>
      <tr>
        <th scope="col" class="text-center" style="width: 2em">#</th>
        <th scope="col" class="text-center" style="width: 40em">NAME</th>
        <th scope="col" class="text-center" style="width: 40em">CODE</th>
        <th scope="col" class="text-center" style="width: 2em">ACTION</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading && !engineAuxMaker.length">
        <td colspan="100%"><app-loading></app-loading></td>
      </tr>
      <tr v-else-if="!loading && engineAuxMaker.length" v-for="(engineAuxMakers,index) in engineAuxMaker">
        <td class="align-middle text-center">{{index + 1}}</td>
        <td class="align-middle text-center">{{engineAuxMakers.name ? engineAuxMakers.name.toUpperCase() : ''}}</td>
        <td class="align-middle text-center">{{engineAuxMakers.code ? engineAuxMakers.code.toUpperCase() : ''}}</td>
        <td class="align-middle text-center">
          <div class="btn-group dropleft">
            <button
              id="actionDropdown"
              aria-expanded="false"
              aria-haspopup="true"
              class="mr-2 btn btn-sm btn-secondary force-white-all"
              data-toggle="dropdown"
            >
              <font-awesome-icon icon="cogs"/>
            </button>
            <div aria-labelledby="actionDropdown" class="dropdown-menu">
              <button class="dropdown-item text-secondary font-weight-bolder"
                      @click="setForm(engineAuxMaker)" data-toggle="modal" data-target="#engineAuxMakerModal">
                <font-awesome-icon icon="pen"></font-awesome-icon>
                Edit
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                      @click="removeEngineAuxMaker(engineAuxMaker)"
              >
                <font-awesome-icon icon="trash" class="force-danger-all"/>
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr v-else>
        <td colspan="100%" class="align-middle text-center font-weight-bold bg-secondary text-white">NO RESULTS FOUND</td>
      </tr>
      </tbody>
    </table>
    <div class="modal fade" data-backdrop="static" id="engineAuxMakerModal" tabindex="-1" role="dialog" aria-labelledby="EngineMaker" aria-hidden="true" ref="EngineMaker">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <form class="modal-content" ref="EngineMaker" @submit.prevent="cudEngineAuxMaker">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="newUserTitle">{{engineAuxMakerForm.hasOwnProperty('id') ? 'UPDATE' : 'CREATE'}} ENGINE AUX MAKER</h5>
            <button type="button" class="close" data-dismiss="modal" ref="closeEngineMakerModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row no-gutters mb-1">
              <label for="first_name" class="font-weight-bold col-sm-3 col-form-label">Name :</label>
              <div class="col-sm-9">
                <input type="text" v-model="engineAuxMakerForm.name" id="first_name" class="form-control form-control-sm"  placeholder="Name">
                <div v-if="errorList.name" class="text-danger text-left font-weight-bold mt-1">
                  {{errorList.name.join('/')}}
                </div>
              </div>
            </div>
            <div class="form-group row no-gutters mb-1">
              <label for="first_name" class="font-weight-bold col-sm-3 col-form-label">Code :</label>
              <div class="col-sm-9">
                <input type="text" v-model="engineAuxMakerForm.code" id="first_name" class="form-control form-control-sm" placeholder="Code">
                <div v-if="errorList.code" class="text-danger text-left font-weight-bold mt-1">
                  {{errorList.code.join('/')}}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
<!--            <button type="button" class="btn btn-sm btn-secondary font-weight-bold" ref="closeEngineMakerModal" @click="resetForm" data-dismiss="modal">Close</button>-->
            <button type="submit" class="btn btn-sm font-weight-bold" :class="{
                'btn-success':!engineAuxMakerForm.hasOwnProperty('id'),
                'btn-warning':engineAuxMakerForm.hasOwnProperty('id'),
                }">{{engineAuxMakerForm.hasOwnProperty('id') ? 'Update' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import appLoading from "@/components/elements/AppLoading";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import _ from 'lodash';
import {PaginationMixin} from "@/mixins/PaginationMixin"

export default {
  name: "SettingsEngineAuxMaker",
  data(){
    return{
      searchParams:{
        search:'',
        page:1,
      },
      loading: false,
      engineAuxMakerForm:{},
      errorList:{}
    }
  },
  components:{appLoading},
  mixins: [PaginationMixin],
  computed:{
    ...mapGetters(['engineAuxMaker'])
  },
  async created() {
    this.engineMakerSearch=_.debounce(this.setPage,750)
    this.loading=true;
    await this.getEngineAuxMakers();
    this.loading=false;
  },
  watch:{
    "searchParams.search"(){
      this.engineMakerSearch();
    }
  },

  methods : {
    ...mapActions(['getEngineAuxMakers','createEngineAuxMaker','updateEngineAuxMaker','deleteEngineAuxMaker']),

    async removeEngineAuxMaker(engineAuxMaker){
      if( await AlertService.confirmDeleteAlert('Delete Engine Aux Maker', 'Do you want to delete this engine aux maker ?','Yes, Delete It')) {
        const response = await this.deleteEngineAuxMaker(engineAuxMaker)
        if(response) {
          await this.getEngineAuxMakers(this.searchParams)
          AlertService.successAlert('Engine Aux Maker Deleted Successfully','DELETE ENGINE AUX MAKER')
        }

      }

    },

    setPage(page){
      this.searchParams.page = page ?? 1
      this.getEngineAuxMakers(this.searchParams)
    },

    setForm(form){
      this.resetForm()
      this.engineAuxMakerForm=Object.assign({},form);
    },

    resetForm(){
      this.engineAuxMakerForm={}
      this.errorList={}
    },

    async cudEngineAuxMaker(){
      let response=null
      let hasId=false;
      if(!this.engineAuxMakerForm.hasOwnProperty('id')){
        if(await AlertService.confirmCreateAlert('Do yo want to add new engine aux maker?')){
          response = await this.createEngineAuxMaker(this.engineAuxMakerForm)
        }
      }
      else {
        hasId=true;
        if(await AlertService.confirmUpdateAlert('Do yo want to update this engine aux maker')){
          response = await this.updateEngineAuxMaker(this.engineAuxMakerForm)
        }
      }


      // CHECK RESPONSE IF SUCCESS OR FAILED
      if(response && !response.errors){
        const action= hasId ? 'Updated': 'Created';
        const actionType= hasId ? 'UPDATE': 'CREATE';
        await AlertService.successAlert(`Engine Aux Maker ${action} Successfully`,`${actionType} ENGINE AUX MAKER`);
        await this.getEngineAuxMakers(this.searchParams)
        this.$refs.closeEngineMakerModal.click();
      }

      else {
        this.errorList= response?.errors ?? {};
      }
    },
  }

}
</script>

<style scoped>

</style>
